@media (max-width: 1880px) {
    .settingpage-timer_block_Left .settingpage-TextFieldRoot:first-child {
        margin-right: 10px;
    }
}

@media (max-width: 1800px) {
    .loadbalancer-section_2 .loadbalancer-cards_row_First .Dashboard-Col_4 {
        width: 50%;
    }

    .Dashboard-cards_row_First .Dashboard-Col_4,
    .Dashboard-cards_row_Second .Dashboard-Col_4 {
        width: 50%;
    }

    .GraphPage-GridRoot_container .GraphPage-section_2 .GraphPage-Graph_row .Dashboard-Col_6 {
        width: 100%;
    }

    .settingpage-section_1 .Dashboard-cards_row .Dashboard-Col_4 {
        width: 50%;
    }
}

@media (max-width: 1280px) {

    .settingpage-section_1 .Dashboard-cards_row .Dashboard-Col_4 {
        width: 100%;
    }

    .settingpage-cards_row_First_item1 .settingpage-timer_block_parent {
        max-height: unset;
        overflow: unset;
        padding-right: 0px;
    }

    .settingpage-cards_row_First_item1 .settingpage-timer_block_parent .settingpage-timer_block {
        padding-top: 20px;
    }
}


@media (max-width: 1199px) {
    .loadbalancer-section_2 .loadbalancer-cards_row_First .Dashboard-Col_4 {
        width: 100%;
    }

    .Dashboard-cards_row_First .Dashboard-Col_4,
    .Dashboard-cards_row_Second .Dashboard-Col_4 {
        width: 100%;
    }

    .MuiPickersPopper-root {
        margin-left: 12px !important;
    }
}

@media (max-width: 599.95px) {
    .settingpage-Slider_block .MuiSlider-markLabel {
        font-size: 14px;
        top: 32px;
    }
}