 *, *::before, *::after {
    box-sizing: inherit;
  }
  html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
  }
  
  html body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  
  .App {
    width: 100vw;
    overflow-x: inherit;
  }
